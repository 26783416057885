import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import arrowImage from "../../../assests/img/arrow.svg"
// import MachineDocument from '../Machine Documents';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useGetMachineInfoByIdQuery, useSitesListByOrgIdQuery } from '../../../redux-services';
import MachineWorkLogs from '../Machine Work Logs';
import MachineDetail from '../ViewMachineInfo/MachineDetail';
import MachineDocuments from '../Machine Documents';
import FuelEntry from './FuelEntry';
import MachinerySpecificWorkLogs from './MachinerySpecificWorkLogs';
import { Order, PageMeta, SortingData } from '../../../types/CommonTypes';
import MachineryServiceRecords from './MachineryServiceRecords';
import BackButtonListener from '../../../components/BackButton/BackButton';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
 
let CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
 
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`organization-tabpanel-${index}`}
            aria-labelledby={`organization-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
 
let a11yProps = (index: number) => {
    return {
        id: `organization-tab-${index}`,
        'aria-controls': `organization-tabpanel-${index}`,
    };
};
 
 
let initialOrder: string = process.env.REACT_APP_ORDER as string;
 
const MachineOverview = () => {
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: 'name',
    };
    let query = useLocation();
    const { id } = useParams<string>();
    const [organizationName, setOrganizationName] = useState<string>('');
    const [orgId, setOrgId] = useState<string>('');
    const [machineName, setMachineName] = useState<string>('');
    const userDetail = useSelector((state: RootState) => state.auth.user);
 
 
    const [value, setValue] = React.useState<number>(
        localStorage.getItem('selectedTabMachineryDetails') ? parseInt(localStorage.getItem('selectedTabMachineryDetails') || '0', 10) : 0
    );
    const [sitesList, setSitesList] = useState<PageMeta>(initialState);
 
    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        localStorage.setItem('selectedTabMachineryDetails', newValue.toString())
    }, []);
    const { data: getData } = useGetMachineInfoByIdQuery(id, {
        skip: !id,
    });
 
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    //const [organizationName, setOrganizationName] = useState<string>('');
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const organizationId = localStorage.getItem("orgId");
    const { data: sitesListData } = useSitesListByOrgIdQuery({
        order: sitesList?.order ?? order,
        order_by: sitesList?.order_by ?? orderBy,
        organization_id: organizationId ?? '',
    });
 
    useEffect(()=>{
        setSitesList(sitesListData?.response?.data);
    },[sitesListData?.response?.data]);
    console.log('Sites List', sitesList);
 
    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response ;
            console.log("orgnaemme",data);
            setOrganizationName(data?.organization_name ?? '');
            setOrgId(data?.machinetypeData?.organization_id ?? "");
            const machineType = data?.machine_type ?? '';
            const numberPlate = data?.number_plate ?? '';
            if (numberPlate) {
                const lastFourDigits = numberPlate.slice(-4);
                setMachineName(`${machineType}-${lastFourDigits}`);
            } else {
                setMachineName(machineType);
            }
        }
    }, [getData]);
    const breadcrumbs = [
        ...(userDetail?.role_type === 0 ? [{ name: 'Organizations', to: '/admin/organization' }] : []),
        { name: 'Machinery Lists', to: `/admin/machine` },
        { name: machineName, to: `admin/machine/${id}` },
    ];
 
 
    return (
        <>
        <BackButtonListener/>
        <Box sx={{ flexGrow: 1 }} className='mt-3'>
            <Toolbar>
                <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                    {breadcrumbs.map((crumb, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                            {index === breadcrumbs.length - 1 ? (
                                <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                            ) : (
                                <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Toolbar>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Machinery Detail" {...a11yProps(0)} />
                    <Tab label="Machinery Documents" {...a11yProps(1)} />
                    <Tab label="Machinery Logs" {...a11yProps(2)} />
                    <Tab label="Machinery Service" {...a11yProps(3)} />
                    <Tab label="Fuel Entries" {...a11yProps(4)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <MachineDetail paramId={id as string} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <MachineDocuments />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Box>
                    <MachinerySpecificWorkLogs sitesList={sitesList}/>
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <Box>
                    <MachineryServiceRecords sitesList ={sitesList} />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <Box>
                    <FuelEntry />
                </Box>
            </CustomTabPanel>
        </Box>
        </>
    )
}
 
export default MachineOverview