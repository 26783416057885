import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
const BackButtonListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      navigate(-1);  
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return null;
};

export default BackButtonListener;