import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import BadgeIcon from '@mui/icons-material/Badge';
import TrackChangesSharp from '@mui/icons-material/TrackChangesSharp';
// import WorkIcon from '@mui/icons-material/Work';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

const SuperAdminNav = [
  {
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: <SpaceDashboardIcon className='icon' />,
  },
  {
    name: "Organization",
    to: "/admin/organization",
    icon: <CorporateFareIcon className='icon' />,
  },

  {
    name: "User",
    to: "/admin/user",
    icon: <PersonIcon className='icon' />,
    child: [{
      name: "User",
      to: "/admin/user",
      icon: <PersonIcon className='icon' />,
    },
   
    {
      name: "Role",
      to: "/admin/user/role",
      icon: <ManageAccountsIcon className='icon' />,
    }]
  },
  
  {
    name: "Fuel Type",
    to: "/admin/fuel-type",
    icon: <LocalGasStationIcon className='icon' />,
  },

  {
    name: "Tracker Details",
    to: "/admin/tracker",
    icon: <TrackChangesSharp className='icon' />,
  },
 
];

const OrgNav = [
  {
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: <SpaceDashboardIcon className='icon' />,
  },
  {
    name: "Sites",
    to: "/admin/organization/:id",
    icon: <AccountTreeIcon className='icon' />,
  },
  {
    name: "User",
    to: "/admin/user",
    icon: <PersonIcon className='icon' />,
    child: [{
      name: "User",
      to: "/admin/user",
      icon: <PersonIcon className='icon' />,
    },
    {
      name: "Employee",
      to: "/admin/user/employee",
      icon: <BadgeIcon className='icon' />,
    },
    {
      name: "Role",
      to: "/admin/user/role",
      icon: <ManageAccountsIcon className='icon' />,
    }]
  },
  {
    name: "Machinery",
    to: "/admin/machine",
    icon: <PrecisionManufacturingIcon className='icon' />,
  },
  // {
  //   name: "Shifts",
  //   to: "/admin/shift",
  //   icon: <WorkIcon className='icon' />,
  // },
  {
    name: "Tracker Details",
    to: "/admin/tracker",
    icon: <TrackChangesSharp className='icon' />,
  }
];

const SubSuperAdminNav = [
  {
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: <SpaceDashboardIcon className='icon' />,
  },
  {
    name: "Organization",
    to: "/admin/organization",
    icon: <CorporateFareIcon className='icon' />,
  },
  {
    name: "User",
    to: "/admin/user",
    icon: <PersonIcon className='icon' />,
    child: [{
      name: "User",
      to: "/admin/user",
      icon: <PersonIcon className='icon' />,
    }]
  },
  {
    name: "Tracker Details",
    to: "/admin/tracker",
    icon: <TrackChangesSharp className='icon' />,
  },
];


export default { superAdminNav: SuperAdminNav, orgNav: OrgNav, subSuperAdminNav: SubSuperAdminNav };
