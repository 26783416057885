import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
    Button,
    Paper,
    Snackbar,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../types/CommonTypes';
import { SearchElement } from '../../../components/Search/Search';
import { TableHeadElement } from '../../../components/TableBody/TableHeadElement';
import { TableBodyElement } from '../../../components/TableBody/TableBodyElement';
import {  useGetMachineWorkLogBySiteIdQuery } from '../../../redux-services';


const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "machine_name",
        sort: true,
        label: "Machine Type",
    },
    {
        id: "company",
        sort: true,
        label: "Company",
    },
    {
        id: "machine_model",
        sort: true,
        label: "Model"
    },
    {
        id: "number_plate",
        sort: true,
        label: "Number Plate"
    },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const CompletedSiteMachineInfo: FC<{ siteDetail: { id: string, name: string } }> = ({ siteDetail }) => {
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };
    let navigate = useNavigate();
    let query = useLocation();
    let { id: siteId } = useParams<string>();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );

    const [machineInfoList, setMachineInfoList] = useState<PageMeta>(initialState);

    const { data } = useGetMachineWorkLogBySiteIdQuery({
        per_page: machineInfoList.per_page ?? rowsPerPage,
        page: query.state ?? machineInfoList.page,
        order: machineInfoList.order ?? order,
        order_by: machineInfoList.order_by ?? orderBy,
        searchText: machineInfoList.searchText ?? search,
        id: siteId
    });
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setMachineInfoList({
            ...machineInfoList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setMachineInfoList({ ...machineInfoList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setMachineInfoList({
            ...machineInfoList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setMachineInfoList({
                page: 1,
                per_page: machineInfoList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

  
    
    return (
        <div>
            <Paper className="table-outers mb-2">
                <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 40%' }}
                        variant="h5"
                        id="tableTitle"
                        component="div"
                        className="inner-headings"
                    >
                        Machines Used On This Site
                    </Typography>
                    <div className="flex-boxinner">
                        <Typography
                            sx={{ flex: '1 1 auto' }}
                        >
                            <SearchElement
                                searchFn={searchHandler}
                                searchTag={"Search Machinery..."}
                            />

                        </Typography>
                    </div>
                </Toolbar>
                    <StyledEngineProvider>
                        <TableContainer component={Paper} className="table-outers">
                            <Table
                                sx={{ minWidth: 650 }}
                                size="small" aria-label="a dense table"
                                className="table-outers"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableHeadElement
                                            setHead={tableColumns ? tableColumns : []}
                                            order={order}
                                            orderBy={orderBy}
                                            sortFn={(event: any, id: string) =>
                                                createSortHandler(event, id as keyof SortingData)
                                            }
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableBodyElement
                                        selectedColumns={[
                                            "machine_name",
                                            "company",
                                            "machine_model",
                                            "number_plate",
                                        ]}
                                        setData={data?.response.data ? data?.response.data : []}
                                        pageData={{
                                            limit:
                                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                            page: data?.response.page as number,
                                        }}
                                    />
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={data?.response.total ?? 0}
                                page={data?.response.page ?? 0}
                                rowsPerPageOptions={[20, 50, 100]}
                                onPageChange={handleChangePage}
                                rowsPerPage={
                                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </StyledEngineProvider>
            </Paper>
          
        </div>
    )
}

export default memo(CompletedSiteMachineInfo);