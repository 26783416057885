import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Snackbar,
  StyledEngineProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  TablePagination,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../../../types/CommonTypes";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  useDeleteMachineInfoMutation,
  useFuelStorageListQuery,
  useGetSitesByIdQuery,
  useUpdateFuelStorageStatusMutation,
} from "../../../../redux-services";
import AddEditFuelStorage from "./AddEditFuelStorage";
import FuelTransactionView from "./FuelTransactionView";

import { FuelTypes, FuelUnit } from "../../../../ constants";
import ConfirmBox from "../../../../components/ConfirmBox/ConfirmBox";
import { TableHeadElement } from "../../../../components/TableBody/TableHeadElement";
const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "fuel_type",
    sort: true,
    label: "Fuel Type",
  },
  {
    id: "quantity",
    sort: true,
    label: "Current Fuel Quantity",
  },
  {
    id: "date_time",
    sort: true,
    label: "Time",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const FuelStorage: FC = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
    isDocument: false,
  };
  let query = useLocation();
  let navigate = useNavigate();
  let { id } = useParams();
  const isChecked = false;
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [activeMachineId, setActiveMachineId] = useState<string>("");
  const machineStatus = false;
  const search = "";
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [isActive, setIsActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [machineInfoList, setMachineInfoList] =
    useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");
  const [editId, setEditId] = useState<string>("");
  const [fuelStorageData, setFuelStorageData] = useState<[]>([]);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [page, setPage] = useState<number>(1);

  const { isSuccess, data } = useFuelStorageListQuery({
    per_page: machineInfoList?.per_page ?? rowsPerPage,
    page: query.state ?? machineInfoList.page,
    order: machineInfoList.order ?? order,
    order_by: machineInfoList.order_by ?? orderBy,
    searchText: machineInfoList.searchText ?? search,
    isDocument: machineInfoList.isDocument ?? isChecked,
    id: id,
  });
  const { data: getData } = useGetSitesByIdQuery(id);
  useEffect(() => {
    if (getData?.status === 200) {
      const responseData = getData?.response || [];
      let data = responseData;
      setIsButtonDisabled(data.is_deactivate)
      setIsActive(data.is_active);
    }
  }, [getData]);

  useEffect(() => {
    if (isSuccess === true) {
      const fData = data?.response.data.map((item: any) => {
        let FuelType = FuelTypes.find(
          (items: { id: number; title: string }) => items.id === item.fuel_type
        );

        return {
          ...item,
          quantity: `${item.quantity} ${FuelUnit[item.fuel_unit]}`,
          fuel_type: `${FuelType?.title} (${item.company})`,
        };
      });
      setFuelStorageData(fData);
    } else {
      setFuelStorageData([]);
    }
  }, [isSuccess, data]);

  const [deleteMachineInfo] = useDeleteMachineInfoMutation();
  const [updateActiveStatus] = useUpdateFuelStorageStatusMutation();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setMachineInfoList({
      ...machineInfoList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteMachineInfo(Id);
    } else {
      setIsClose(res);
    }
  };
  const handleModel = useCallback(
    (isOpen: boolean) => {
      if (isOpen !== true) {
        setEditId("");
      }
      setOpen(isOpen);
    },
    [open]
  );

  const handleConfirmationResponse = (confirmed: boolean) => {
    if (confirmed) {
      const newStatus = !machineStatus;
      updateActiveStatus({ id: activeMachineId, status: newStatus });
    }
    setActiveMachineId("");
    setIsConfirmationOpen(false);
  };
  const handleShowMessage = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };

  const handleRowClick = (id: string) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const actionText = machineStatus ? "Deactivate" : "Activate";
  const confirmationMessage = activeMachineId
    ? `Are you sure you want to ${actionText.toLowerCase()} this Fuel Storage?`
    : "";

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setMachineInfoList({ ...machineInfoList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMachineInfoList({
      ...machineInfoList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  return (
    <>
      <Paper className="table-outers mb-2">
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 40%" }}
            variant="h5"
            id="tableTitle"
            component="div"
            className="inner-headings"
          >
            Fuel Storage
          </Typography>

          <div className="flex-boxinner">
            <Typography sx={{ flex: "1 1 auto" }}></Typography>
            {isButtonDisabled ? null : (
              <Tooltip title="Add Machine Info" arrow className="me-1 mt-1">
                <Button
                  className="secondary-btn"
                  onClick={() => {
                    setOpen(true);
                    handleShowMessage();
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isButtonDisabled}
                >
                  Add Fuel Storage
                </Button>
              </Tooltip>
            )}
            <Snackbar
              open={snackbarOpen && !isActive}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
              message="Please activate this site to add Fuel Storage logs."
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              style={{ marginTop: "140px" }}
            />
          </div>
        </Toolbar>
        <StyledEngineProvider>
          <TableContainer component={Paper} className="table-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers"
            >
              <TableHead>
                <TableRow>
                  <TableHeadElement
                    setHead={tableColumns ? tableColumns : []}
                    order={order}
                    orderBy={orderBy}
                    sortFn={(event: any, id: string) =>
                      createSortHandler(event, id as keyof SortingData)
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {fuelStorageData.map((row: any, index: number) => (
                  <React.Fragment key={row._id}>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      style={{
                        backgroundColor:
                          expandedRow === row._id ? "#7d8b1a" : "inherit",
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.fuel_type}</TableCell>
                      <TableCell>{row.quantity}</TableCell>
                      <TableCell>{row.date_time}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleRowClick(row._id)}
                        >
                          {expandedRow === row._id ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          display: "table-cell",
                        }}
                        colSpan={6}
                      >
                        <Collapse
                          in={expandedRow === row._id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <FuelTransactionView
                              isButtonDisabled={isButtonDisabled}
                              viewData={{ open: true, id: row._id }}
                              handleViewModel={() => { }}
                            />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={data?.response?.total ?? 0}
              page={data?.response?.page ?? 0}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={handleChangePage}
              rowsPerPage={
                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </StyledEngineProvider>
      </Paper>
      <ConfirmBox
        title={`Fuel Storage ${machineStatus ? "Deactivate" : "Activate"
          } Confirmation`}
        message={confirmationMessage}
        open={isConfirmationOpen}
        onClose={handleConfirmationResponse}
      />
      <ConfirmBox
        title="Fuel Storage Delete Confirmation"
        message="Are you sure, you want to delete this fuel storage?"
        open={isClose}
        onClose={confirmBoxClose}
      />
      {open && isActive && (
        <AddEditFuelStorage
          open={open}
          handleModel={handleModel}
          siteId={id}
          storageId={editId}
        />
      )}
    </>
  );
};

export default FuelStorage;
