import React, { FC, useMemo, useState } from "react";
import {
  Paper,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import { TableHeadElement } from '../../../components/TableBody/TableHeadElement';
import { TableBodyElement } from '../../../components/TableBody/TableBodyElement';
import { ColumnsType,Order, PageMeta, SortingData } from '../../../types/CommonTypes';
import {
  useGetCompletedSiteEmployeeQuery,
} from '../../../redux-services';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SearchElement } from '../../../components/Search/Search';
const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "username",
    sort: true,
    label: "Employee Name",
  },
  {
    id: "phone",
    sort: false,
    label: "Contact No.",
  },
  {
    id: "role_name",
    sort: false,
    label: "Employee Role",
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const CompletedSiteEmployee: FC<{ siteDetail: { id: string; name: string } }> = ({
  siteDetail,
}) => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  let navigate = useNavigate();
  let query = useLocation();
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [userList, setUserList] = useState<PageMeta>(initialState);
   let { id: siteId } = useParams<string>();

   const { data } = useGetCompletedSiteEmployeeQuery({
         per_page: userList.per_page ?? rowsPerPage,
         page: query.state ?? userList.page,
         order: userList.order ?? order,
         order_by: userList.order_by ?? orderBy,
         searchText: userList.searchText ?? search,
         id: siteId
     });
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setUserList({
      ...userList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setUserList({ ...userList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserList({
      ...userList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setUserList({
        page: 1,
        per_page: userList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, [userList.per_page]);
  
  return (
    <>
        <div className="table">
          <Paper className="table-outers">
            <Toolbar>
              <Typography
                sx={{ flex: "1 1 40%" }}
                variant="h5"
                id="tableTitle"
                component="div"
                className="inner-headings">
                Worked Employee On This Site
              </Typography>

              <div className="flex-boxinner">
                <Typography sx={{ flex: "1 1 auto" }}>
                  <SearchElement
                    searchFn={searchHandler}
                    searchTag={`Search Employee...`}
                  />
                </Typography>
              </div>
            </Toolbar>
            <StyledEngineProvider>
              <TableContainer component={Paper} className="table-outers">
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableHeadElement
                        setHead={tableColumns ? tableColumns : []}
                        order={order}
                        orderBy={orderBy}
                        sortFn={(event: any, id: string) =>
                          createSortHandler(event, id as keyof SortingData)
                        }
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyElement
                      selectedColumns={["username", "phone", "role_name"]}
                      setData={data?.response?.data ? data?.response?.data : []}
                      pageData={{
                        limit:
                          rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                        page: data?.response.page as number,
                      }}
                    />
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={data?.response?.total ?? 0}
                  page={data?.response?.page ?? 0}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageChange={handleChangePage}
                  rowsPerPage={
                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </StyledEngineProvider>
          </Paper>
        </div>
    </>
  );
};
export default CompletedSiteEmployee;
