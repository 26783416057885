import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Paper,
  Snackbar,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  DialogActions,
  Dialog,
  Toolbar,
//   Tooltip,
  Typography,
  Autocomplete,
  DialogTitle,
  TextField,
  DialogContent,
  debounce,
  Box,
  Modal,
} from "@mui/material";
import { TableBodyElement } from "../../../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  MachineType,
  Order,
  PageMeta,
  SortingData,
} from "../../../../types/CommonTypes";
import {
  useDeleteEmployeeMutation,
  useUpdateEmployeeStatusMutation,
  useUpdateEmployeeSiteMutation,
  useSitesListByOrgIdQuery,
  useEmployeeListQuery,
  useGetSitesByIdQuery,
} from "../../../../redux-services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmBox from "../../../../components/ConfirmBox/ConfirmBox";
import { SearchElement } from "../../../../components/Search/Search";
import EmployeeAddEditModal from "./EmployeeAddEditModal";
// import TakeAttendance from "./TakeAttendance";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "username",
    sort: true,
    label: "Employee Name",
  },
  {
    id: "phone",
    sort: false,
    label: "Contact No.",
  },
  {
    id: "role_name",
    sort: false,
    label: "Employee Role",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const SiteUserList: FC<{ siteDetail: { id: string; name: string } }> = ({
  siteDetail,
}) => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  let navigate = useNavigate();
  let query = useLocation();

  const { id } = useParams();

  const [page, setPage] = useState<number>(1);

  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [activeMachineId, setActiveMachineId] = useState<string>("");
  const [machineStatus, setMachineStatus] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [userList, setUserList] = useState<PageMeta>(initialState);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const siteOrgId = useSelector((state: RootState) => state.auth.siteOrgId);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
//   const [openAttendanceModal, setOpenAttendanceModal] = useState(false);
  const [isSitePopupOpen, setIsSitePopupOpen] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<{
    siteId: string;
    siteName: string;
  } | null>(null);

  const { data } = useEmployeeListQuery(
    {
      per_page: userList.per_page ?? rowsPerPage,
      page: query.state ?? userList.page,
      order: userList.order ?? order,
      order_by: userList.order_by ?? orderBy,
      searchText: userList.searchText ?? search,
      site_id: id,
      organization_id: siteOrgId,
    },
    { skip: !siteOrgId }
  );
  const { data: getData } = useGetSitesByIdQuery(id);
  useEffect(() => {
    if (getData?.status === 200) {
      let data = getData?.response;
      setIsButtonDisabled(data.is_deactivate);
      setIsActive(data.is_active);
    }
  }, [getData]);
  const organizationId = localStorage.getItem("orgId");

  const { data: siteData } = useSitesListByOrgIdQuery({
    order: "asc",
    order_by: "name",
    organization_id: organizationId,
  });

  const [deleteEmployee] = useDeleteEmployeeMutation();
  const [updateEmployeeStatus] = useUpdateEmployeeStatusMutation();
  const handleMoveToSite = (empId: string) => {
    setActiveMachineId(empId);
    setIsSitePopupOpen(true);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setUserList({
      ...userList,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setUserList({ ...userList, page: newPage + 1 });

    if (query.state !== null) {
      navigate(query.pathname, {});
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserList({
      ...userList,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setUserList({
        page: 1,
        per_page: userList.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, [userList.per_page]);
  const handleSiteSelection = (siteId: string, siteName: string) => {
    setSelectedSite({ siteId, siteName });
  };
  const [updateEmployeeSite] = useUpdateEmployeeSiteMutation();
  const handleMoveConfirmation = async (confirmed: boolean) => {
    if (confirmed && selectedSite && activeMachineId) {
      try {
        const response = await updateEmployeeSite({
          id: activeMachineId,
          site_id: selectedSite.siteId,
        }).unwrap();

        console.log("Employee site updated successfully", response);
      } catch (error) {
        console.error("Failed to update employee to  site", error);
      }
    }

    setIsSitePopupOpen(false);
    setIsConfirmationOpen(false);
    setSelectedSite(null);
  };

  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteEmployee(Id);
    } else {
      setIsClose(res);
    }
  };
  const handleModel = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);
  const handleToggleActive = (id: string) => {
    const machine = data?.response.data.find(
      (machine: MachineType) => machine._id === id
    );
    if (machine) {
      setActiveMachineId(id);
      setIsConfirmationOpen(true);
      setMachineStatus(machine.is_active);
    }
  };
  const handleConfirmationResponse = (confirmed: boolean) => {
    if (confirmed) {
      const newStatus = !machineStatus;
      updateEmployeeStatus({ id: activeMachineId, status: newStatus });
    }
    setActiveMachineId("");
    setIsConfirmationOpen(false);
  };
//   const handleShowMessage = () => {
//     setSnackbarOpen(true);
//     setTimeout(() => {
//       setSnackbarOpen(false);
//     }, 3000);
//   };
  const isActivefn = (id: any) => {
    if (isButtonDisabled) {
      alert("Cannot toggle active status (site is deactivated)");
      return;
    }
    handleToggleActive(id);
  };
  const deleteFn = (id: string) => {
    if (isButtonDisabled) {
      alert("Completed site cannot be deleted");
      return;
    }
    setIsClose(true);
    setId(id);
  };
  const editFn = (id: string) => {
    if (isButtonDisabled) {
      alert("Site cannot be edited as its deactivated");
      return;
    }
    setOpen(true);
    setEditId(id);
  };
  return (
    <>
      {/* {openAttendanceModal ? (
        <TakeAttendance
        //   open={openAttendanceModal}
        //   setOpenAttendanceModal={setOpenAttendanceModal}
          siteInfo={siteDetail}
        />
      ) : ( */}
        <div className="table">
          <Paper className="table-outers">
            <Toolbar>
              <Typography
                sx={{ flex: "1 1 40%" }}
                variant="h5"
                id="tableTitle"
                component="div"
                className="inner-headings">
                Employee List
              </Typography>

              <div className="flex-boxinner">
                <Typography sx={{ flex: "1 1 auto" }}>
                  <SearchElement
                    searchFn={searchHandler}
                    searchTag={`Search Employee...`}
                  />
                </Typography>
                {/* {isButtonDisabled ? null : (
                  <>
                    <Tooltip
                      title={`Explore Employee Attendance`}
                      arrow
                      className="me-1 mt-1">
                      <Button
                        className="secondary-btn"
                        onClick={() => {
                          setOpenAttendanceModal(true);
                          setEditId("");
                          handleShowMessage();
                        }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isButtonDisabled}>
                        Explore Employee Attendance
                      </Button>
                    </Tooltip>
                  </>
                )} */}
                <Snackbar
                  open={snackbarOpen && !isActive}
                  autoHideDuration={3000}
                  onClose={() => setSnackbarOpen(false)}
                  message="Please activate this site to add Employee."
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  style={{ marginTop: "140px" }}
                />
              </div>
            </Toolbar>
            <StyledEngineProvider>
              <TableContainer component={Paper} className="table-outers">
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableHeadElement
                        setHead={tableColumns ? tableColumns : []}
                        order={order}
                        orderBy={orderBy}
                        sortFn={(event: any, id: string) =>
                          createSortHandler(event, id as keyof SortingData)
                        }
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyElement
                      selectedColumns={["username", "phone", "role_name"]}
                      setData={data?.response?.data ? data?.response?.data : []}
                      viewProfileFn={(id: string) => {
                        navigate(`/admin/profile/${id}`, {
                          state: {
                            page,
                            isButtonDisabled,
                          },
                        });
                      }}
                      editFn={editFn}
                      deleteFn={deleteFn}
                      moveFn={(id) => handleMoveToSite(id)}
                      isActivefn={(id) => isActivefn(id)}
                      pageData={{
                        limit:
                          rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                        page: data?.response.page as number,
                      }}
                    />
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={data?.response?.total ?? 0}
                  page={data?.response?.page ?? 0}
                  rowsPerPageOptions={[20, 50, 100]}
                  onPageChange={handleChangePage}
                  rowsPerPage={
                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </StyledEngineProvider>
          </Paper>
          <ConfirmBox
            title={`Employee ${
              machineStatus ? "Deactivate" : "Activate"
            } Confirmation`}
            message={
              activeMachineId
                ? `Are you sure you want to ${
                    machineStatus ? "deactivate" : "activate"
                  } this Employee?`
                : ""
            }
            open={isConfirmationOpen}
            onClose={handleConfirmationResponse}
          />
          <ConfirmBox
            title="Employee Delete Confirmation"
            message="Are you sure, you want to delete this Employee?"
            open={isClose}
            onClose={confirmBoxClose}
          />
          {open && isActive && (
            <EmployeeAddEditModal
              open={open}
              handleModel={handleModel}
              siteInfo={siteDetail}
              id={editId}
            />
          )}
          {isSitePopupOpen && (
            <Modal
            open={isSitePopupOpen}
            onClose={() => setIsSitePopupOpen(false)}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            >
              <Box
           sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               width: 600, // Adjust width as needed
               bgcolor: '#0F1E31', // Dark background like the first UI
               borderRadius: '8px',
               boxShadow: 24,
               p: 4,
               color: '#ffffff',
           }}
       >
           
           <Typography
               id="select-site-modal"
               variant="h6"
               component="h2"
               sx={{ mb: 2, fontWeight: 600, fontSize: '1.5rem' }}
           >
               Select a Site
           </Typography>

          
           <Typography
               sx={{ marginBottom: '12px', color: '#b0b0b0', fontSize: '0.875rem' }}
           >
               Please select a site to move the employee:
           </Typography>

           
           <Autocomplete
               id="site-select"
               // style={{ background: "#0a243d" }}
               options={
                   siteData?.response?.data.filter((site: any) => site.is_deactivate !== true) ||
                   []
               }
               getOptionLabel={(option) => option.name}
               value={
                   selectedSite
                       ? { _id: selectedSite.siteId, name: selectedSite.siteName }
                       : undefined
               }
               onChange={(event, newValue) => {
                   if (newValue) {
                       handleSiteSelection(newValue._id, newValue.name);
                   }
               }}
               isOptionEqualToValue={(option, value) => option._id === value?._id}
               disableClearable
               
               renderInput={(params) => (
                   <TextField
                   // style={{ background: "#0a243d" }}
                       {...params}
                       label="Select site"
                       fullWidth
                       variant="outlined"
                        className="raju-clas"
                       // InputLabelProps={{
                       //     style: { color: '#b0b0b0' },
                       // }}
                       // InputProps={{
                       //     ...params.InputProps,
                       //     style: {
                       //         backgroundColor: '#153655',
                       //         color: '#ffffff',
                       //         borderRadius: '8px',
                       //         border: '1px solid #2C3B52',
                       //     },
                       // }}
                       // style={{ margin: '8px 0' }}
                   />
               )}
           />

           
           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
               <Button
                   onClick={() => setIsSitePopupOpen(false)}
                   className="close-btn"
                   style={{ marginRight: "10px" }}
                   // sx={{
                   //     color: '#b0b0b0',
                   //     textTransform: 'none',
                   //     mr: 2,
                   // }}
               >
                   Cancel
               </Button>
               <Button
                   onClick={() => setIsConfirmationOpen(true)}
                   className="save-btn"
                   
                   // sx={{
                   //     backgroundColor: '#204F76',
                   //     color: '#ffffff',
                   //     textTransform: 'none',
                   //     '&:hover': {
                   //         backgroundColor: '#1a3d5e',
                   //     },
                   // }}
                   disabled={!selectedSite}
               >
                   Move
               </Button>
           </Box>
       </Box>
       </Modal>
          )}
          {isConfirmationOpen && selectedSite && (
            <ConfirmBox
              title="Move Employee Confirmation"
              message={`Are you sure you want to move this employee to ${selectedSite.siteName} site ?`}
              open={isConfirmationOpen}
              onClose={handleMoveConfirmation}
            />
          )}
        </div>
      {/* )} */}
    </>
  );
};

export default SiteUserList;
