import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
    Paper,
    SelectChangeEvent,
    Snackbar,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Typography,
    debounce,
} from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { TableBodyElement } from "../../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../../components/TableBody/TableHeadElement";
import {
    ColumnsType,
    Order,
    PageMeta,
    SortingData,
} from "../../../types/CommonTypes";
import { SearchElement } from "../../../components/Search/Search";
import {
    useDeleteMachineWorkLogMutation,
    useMachineWorkLogRecordListQuery,
} from "../../../redux-services";

const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "machine_name",
        sort: true,
        label: "Machinery Name",
    },
    {
        id: "user_name",
        sort: true,
        label: "User Name",
    },
    {
        id: "work_type",
        sort: true,
        label: "Work Type",
    },
    {
        id: "entry_date",
        sort: true,
        label: "Entry Date",
    },
    {
        id: "measurement",
        sort: true,
        label: "Measurement",
    },
    {
        id: "site_name",
        sort: true,
        label: "Site Name",
    },
    {
        id:"bore_area",
        sort:true,
        label:"Bore Area"
    },
    {
        id: "from_reading",
        sort: true,
        label: "From Reading"
    },
    {
        id: "to_reading",
        sort: true,
        label: "To Reading"
    }

];

interface MachineSpecificRecordLogs {
    sitesList: any;
}

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const MachinerySpecificWorkLogs: FC<MachineSpecificRecordLogs> = ({ sitesList }) => {
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };
    let navigate = useNavigate();
    let query = useLocation();
    const { id } = useParams<string>();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [tollList, setTollList] = useState<PageMeta>(initialState);
    const [dateRange, setDateRange] = useState({
        startDate: "",
        endDate: "",
    });
    const [shiftLogsList, setShiftLogsList] = useState<PageMeta>(initialState);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedSite, setSelectedSite] = useState<any>(sitesList[0]?._id);

    const selectChange = (event: SelectChangeEvent | any) => {
      setSelectedSite(event.target.value?._id);
    };
    const { data: getMachineData } = useMachineWorkLogRecordListQuery({
        per_page: shiftLogsList.per_page ?? rowsPerPage,
        page: query.state ?? shiftLogsList.page,
        order: shiftLogsList.order ?? order,
        order_by: shiftLogsList.order_by ?? orderBy,
        searchText: shiftLogsList.searchText ?? search,
        startDate: dateRange.startDate ? dateRange.startDate : "",
        endDate: dateRange.endDate ? dateRange.endDate : "",
        id: id ?? ""
    });
   
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setShiftLogsList({
            ...shiftLogsList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setShiftLogsList({ ...shiftLogsList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setShiftLogsList({
            ...shiftLogsList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setShiftLogsList({
                page: 1,
                per_page: shiftLogsList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    return (
        <>
            <div>
                <Paper className="form-outers">
                    <Toolbar>
                        <Typography
                            sx={{ flex: "1 1 40%" }}
                            variant="h5"
                            id="tableTitle"
                            className="inner-headings"
                            component="div">
                            Machine Logs List
                        </Typography>
                        <div className="flex-boxinner">
                            <Typography sx={{ flex: "1 1 auto" }}>
                                <SearchElement
                                    searchFn={searchHandler}
                                    searchTag={"Search Machine Logs..."}
                                />
                            </Typography>
                            <Snackbar
                                open={snackbarOpen && !isActive}
                                autoHideDuration={3000}
                                onClose={() => setSnackbarOpen(false)}
                                message="Please activate this machine to add work logs."
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                style={{ marginTop: "140px" }}
                            />
                        </div>
                    </Toolbar>
                    <StyledEngineProvider>
                        <TableContainer component={Paper} className="form-outers">
                            <Table
                                sx={{ minWidth: 650 }}
                                size="small"
                                aria-label="a dense table"
                                className="table-outers">
                                <TableHead>
                                    <TableRow>
                                        <TableHeadElement
                                            setHead={tableColumns ?? []}
                                            order={order}
                                            orderBy={orderBy}
                                            sortFn={(event, id: string) =>
                                                createSortHandler(event, id as keyof SortingData)
                                            }
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableBodyElement
                                        selectedColumns={[
                                            "machine_name",
                                            "user_name",
                                            "work_type",
                                            "entry_date",
                                            "measurement",
                                            "site_name",
                                            "bore_area_name",
                                            "from_reading",
                                            "to_reading",
                                        ]}
                                        setData={getMachineData?.response.data ? getMachineData?.response.data : []}
                                        pageData={{
                                            limit:
                                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                            page: getMachineData?.response.page as number,
                                        }}
                                    />
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={getMachineData?.response.total ?? 0}
                                page={getMachineData?.response.page ?? 0}
                                rowsPerPageOptions={[20, 50, 100]}
                                onPageChange={handleChangePage}
                                rowsPerPage={
                                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </StyledEngineProvider>
                </Paper>
            </div>
        </>
    );
};

export default MachinerySpecificWorkLogs
