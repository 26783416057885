import { Box, Table, TableBody, TableHead, TablePagination, TableRow, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, DialogContentText } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useGetFuelTransactionQuery, useDeleteFuelTransactionInfoMutation, useGetFuelTransactionByIdQuery } from "../../../../redux-services";
import { ColumnsType, Order, PageMeta, SortingData } from "../../../../types/CommonTypes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TableHeadElement } from "../../../../components/TableBody/TableHeadElement";
import { TableBodyElement } from "../../../../components/TableBody/TableBodyElement";
import { FuelUnit } from "../../../../ constants";
import ConfirmBox from '../../../../components/ConfirmBox/ConfirmBox';
import EditFuelTransaction from "./EditFuelTransaction";
import CloseIcon from '@mui/icons-material/Close';

let initialOrder: string = process.env.REACT_APP_ORDER as string;
const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "refill_quantity",
        sort: true,
        label: "Refill Quantity",
    },
    {
        id: "price_per_unit",
        sort: true,
        label: "Price Per Unit",
    },
    {
        id: "total_fuel_price",
        sort: true,
        label: "Total Fuel Price",
    },
    {
        id: "date_time",
        sort: true,
        label: "Time",
    },
    {
        id: "machinery",
        sort: false,
        label: "Machinery"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
    },
];

const FuelTransactionView: FC<any> = ({ viewData, handleViewModel, isButtonDisabled }) => {
    let query = useLocation();
    let navigate = useNavigate();
    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };
    const [orderBy, setOrderBy] = useState<keyof SortingData>(process.env.REACT_APP_ORDER_BY as keyof SortingData);
    const [open, setOpen] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>("");
    const [isClose, setIsClose] = useState<boolean>(false);
    const [Id, setId] = useState<string>("");
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [fuelData, setFuelData] = useState<[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(initialState.per_page as number);
    const [machineInfoList, setMachineInfoList] = useState<PageMeta>(initialState);
    const [selectedDocumentId, setSelectedDocumentId] = useState<string>("");

    const { isSuccess, data, refetch } = useGetFuelTransactionQuery({
        per_page: machineInfoList.per_page ?? rowsPerPage,
        page: query.state ?? machineInfoList.page,
        order: machineInfoList.order ?? order,
        order_by: machineInfoList.order_by ?? orderBy,
        searchText: machineInfoList.searchText,
        id: viewData.id,
    });
    const { data: transactionData } = useGetFuelTransactionByIdQuery(selectedDocumentId, {
        skip: !selectedDocumentId,
    });
    console.log(transactionData, "Daa")
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>('');
    useEffect(() => {
        if (isSuccess) {
            const fData = data?.response.data.map((item: any) => {
                return {
                    ...item,
                    refill_quantity: `${item.refill_quantity} ${FuelUnit[item.fuel_unit]} ${item.is_delete ? ' (Deleted)' : ''}`,
                    price_per_unit: `₹ ${item.price_per_unit}`,
                    total_fuel_price: `₹ ${item.total_fuel_price}`,
                    machinery: `${item.machinery}`,
                    fuelReceipt_img: item.fuelReceipt_img ? item.fuelReceipt_img.fullUrl : null
                };
            });
            setFuelData(fData);
        } else {
            setFuelData([]);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (transactionData) {
            setImageUrl(transactionData?.response?.fuelReceipt_img_url)
        }
    }, [transactionData]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof SortingData) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setMachineInfoList({
            ...machineInfoList,
            order: newOrder,
            order_by: property,
        });
    };
    const createSortHandler = (event: React.MouseEvent<unknown>, property: keyof SortingData) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setMachineInfoList({ ...machineInfoList, page: newPage + 1 });
        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setMachineInfoList({
            ...machineInfoList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
    };
    const editForm = useCallback((id: string) => {
        setEditId(id);
        setOpen(true);
    }, [open]);

    const [deleteFuelTransactionInfo] = useDeleteFuelTransactionInfoMutation();
    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteFuelTransactionInfo(Id).then(() => {
                refetch();
                handleViewModel(false);
            });
            handleViewModel(false);
        } else {
            setIsClose(res);
        }
    };
    const handleModel = useCallback((isOpen: boolean) => {
        setOpen(isOpen);

    }, []);
    const deleteFn = (id: string) => {
        if (isButtonDisabled) {
            alert("Fuel Storage cannot be deleted as its completed");
            return;
        }
        setIsClose(true);
        setId(id);
    };
    const editFn = (id: string) => {
        if (isButtonDisabled) {
            alert("Fuel Storage cannot be edited as its completed");
            return;
        }
        editForm(id);
    };
    const viewDownloadFn = (downloadId: string) => {
        setSelectedDocumentId(downloadId)
        setImageModalOpen(true);

    };
    const handleModalClose = () => {
        setImageModalOpen(false);
    };
    const handleDownload = () => {
        const document = data?.response?.data.find((doc: { _id: any; }) => doc._id === selectedDocumentId);
        if (document) {
            const fullUrl = document.encodedUrl;
            if (fullUrl) {
                window.open(fullUrl, '_blank');
                handleModalClose();
            }
        }
    };

    return (
        <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
                Transactions
            </Typography>
            <Table size="small" aria-label="a dense table" className="table-outers">
                <TableHead>
                    <TableRow>
                        <TableHeadElement
                            setHead={tableColumns ? tableColumns : []}
                            order={order}
                            orderBy={orderBy}
                            sortFn={(event: any, id: string) =>
                                createSortHandler(event, id as keyof SortingData)
                            }
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableBodyElement
                        selectedColumns={[
                            "refill_quantity",
                            "price_per_unit",
                            "total_fuel_price",
                            "date_time",
                            "machinery"
                        ]}
                        setData={fuelData ? fuelData : []}
                        editFn={editFn}
                        viewDownloadFn={viewDownloadFn}
                        deleteFn={deleteFn}
                        pageData={{
                            limit: rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                            page: data?.response.page as number,
                        }}
                    />
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={data?.response.total ?? 0}
                page={data?.response.page ?? 0}
                rowsPerPageOptions={[20, 50, 100]}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmBox
                title="Fuel Transaction Delete Confirmation"
                message="Are you sure, you want to delete this Fuel Transaction?"
                open={isClose}
                onClose={confirmBoxClose}
            />
            {open && (
                <EditFuelTransaction
                    open={open}
                    handleModel={handleModel}
                    handleViewModal={handleViewModel}
                    transactionId={editId}
                />
            )}
            <Dialog className="dialogBox-main" onClose={handleModalClose} open={imageModalOpen}
                sx={{
                    '& .MuiDialog-paper': {
                        width: '45%',
                        maxWidth: 'none', 
                        overflow: 'hidden', 
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Preview Fuel Receipt
                    <IconButton
                        aria-label="close"
                        onClick={handleModalClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                    </DialogContentText>
                    <img src={imageUrl} alt="Fuel Receipt" />
                </DialogContent>
                <DialogActions>
                    <Button
                        className="close-btn"
                        variant="outlined"
                        color="error"
                        onClick={handleModalClose}
                    >
                        Close
                    </Button>
                    <Button
                        className="save-btn"
                        variant="outlined"
                        color="success"
                        onClick={handleDownload}
                        autoFocus
                    >
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
export default FuelTransactionView;

