import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const DailyLogApi = createApi({
  reducerPath: "dailyLogApi",

  baseQuery: axiosBaseQuery(),

  keepUnusedDataFor: 0,

  tagTypes: ["dailyLogTag"],

  endpoints: (builder) => ({
    dailyLogList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/daily_log/list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          select_date: pageData.select_date ?? "",
          shift_id: pageData.shift_id ?? "",
          site_id: pageData.site_id ?? "",
          endDate: pageData.endDate ?? "",
          organization_id: pageData.organization_id,
        },
      }),
      providesTags: ["dailyLogTag"],
    }),

    createDailyLog: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/daily_log/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["dailyLogTag"],
    }),

    getDailyLogById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/daily_log/${id}`,
        method: "GET",
      }),
      providesTags: ["dailyLogTag"],
    }),

    editDailyLog: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/daily_log/edit/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["dailyLogTag"],
    }),

    getUserListById: builder.query<Response<any>, any>({
      query: (data: any) => ({
        url: `/daily_log/getuser`,
        method: "GET",
        params: {
          site_id: data.site_id,
          work_item_id: data.work_item_id,
        },
      }),
      providesTags: ["dailyLogTag"],
    }),
    findEmployeeList: builder.query<Response<any>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/employee/employee_attendance_list`,
        method: "GET",
        params: {
          select_date: pageData.select_date ?? "",
          shift_id: pageData.shift_id ?? "",
          site_id: pageData.site_id ?? "",
          organization_id: pageData.organization_id,
        },
      }),
      providesTags: ["dailyLogTag"],
    }),

    createDuplicateLog: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/daily_log/duplicate_log`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["dailyLogTag"],
    }),

    markPreviousAttendance: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/daily_log/mark_attandance`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["dailyLogTag"],
    }),

   
    downloadEmployeeAttendance: builder.query<Response<any>, any>({
      query: (params) => ({
        url: `/daily_log/download_employee_attendance`, 
        method: "GET",
        params: {
          select_date: params.select_date, 
          to_date: params.to_date,         
          shift_id: params.shift_id,      
          site_id: params.site_id,         
        },
      }),
      providesTags: ["dailyLogTag"],
    }),

    getDailyLogsByEmployeeId: builder.query<
      Response<PaginatedData<any>>,
      PageMeta
    >({
      query: (pageData: PageMeta) => ({
        url: `/daily_log/attendance_log`,
        method: "GET",
        params: {
          id: pageData.id ?? "",
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["dailyLogTag"],
    }),
    deleteDailyLog: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/daily_log/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["dailyLogTag"],
    }),
  }),
});

export const {
  useDailyLogListQuery,
  useDownloadEmployeeAttendanceQuery,
  useCreateDailyLogMutation,
  useEditDailyLogMutation,
  useGetDailyLogByIdQuery,
  useGetUserListByIdQuery,
  useFindEmployeeListQuery,
  useCreateDuplicateLogMutation,
  useMarkPreviousAttendanceMutation,
  useGetDailyLogsByEmployeeIdQuery,
  useDeleteDailyLogMutation,
} = DailyLogApi;
