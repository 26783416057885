import React, { memo, useEffect, FC, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Divider, Fade, Grid, Modal, TextField, Paper } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Error from '../ErrorBox/Error';
import { useParams } from 'react-router-dom';
import { setError, useEditTrackerInfoMutation, useGetTrackerInfoByIdQuery} from '../../redux-services';
import { useDispatch } from 'react-redux';
import { SelectedVehicleType, TrackerInfoState } from '../../pages/Sites/ViewSites/TrackerDetails/AddEditTrackerInfo';
import { VehicleTypeList } from '../../ constants';
import {  VehicleType } from '../../types/CommonTypes';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const style = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
};

interface FormErrors {
    authorization_id?: string;
    organization_id?: string;
    vehicle_type?: string;
    payload?: string;
    base_url?: string;
}

const TrackerAddEditModal: FC<{ open: boolean, handleModel: any, trackerData?: TrackerInfoState | undefined }> = ({ open, handleModel, trackerData }) => {

    const { id } = useParams();

    const intialState = {
        authorization_id: '',
        organization_id: '',
        base_url:'',
        vehicle_type: VehicleTypeList[0].value,
        selected_vehicle_type: { name: VehicleTypeList[0].name, value: VehicleTypeList[0].value },
        payload: '',
    }

   
    
    let dispatch = useDispatch();
    const [formData, setFormData] = useState<TrackerInfoState>(intialState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [editTrackerInfo, editResult] = useEditTrackerInfoMutation();
    const { data: getTrackerDataById } = useGetTrackerInfoByIdQuery(id, {
        skip: !id,
    });
 

    const handleVehicleType = (data: SelectedVehicleType) => {
        setFormData({ ...formData, vehicle_type: data.value, selected_vehicle_type : data });
        const updatedFormData = { ...formData, vehicle_type: data.value, selected_vehicle_type: data };
        const errors = validate(updatedFormData);

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            'vehicle_type': errors['vehicle_type'],
        }));
    }

    // useEffect(() => {
    //     return () => {
    //         setFormData(intialState)
    //     };
    // }, []);


    useEffect(() => {
        if (getTrackerDataById?.status === 200) {
            setFormData({
                ...formData,
                base_url: getTrackerDataById?.response?.base_url ?? "",
                authorization_id: getTrackerDataById?.response?.authorization_id ?? "",
                organization_id: getTrackerDataById?.response?.organization_id ?? "",
                selected_vehicle_type : VehicleTypeList.find((item: VehicleType) => item.value === getTrackerDataById?.response?.vehicle_type),
                vehicle_type: getTrackerDataById?.response?.vehicle_type ?? 1,
                payload: getTrackerDataById?.response?.payload ?? "",
            })
        }
    }, [getTrackerDataById])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        const updatedFormData = { ...formData, [name]: value };
        const errors = validate(updatedFormData);

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errors[name as keyof FormErrors],
        }));
    }

    const validate = (formData: TrackerInfoState) => {
        const errors: Partial<FormErrors> = {};

        if (!formData?.organization_id?.trim()) {
            errors.organization_id = "Organisation Name is required!";
        }

        if (!formData?.authorization_id?.trim()) {
            errors.authorization_id = "Authorization Id is required!";
        }

        if (!formData?.vehicle_type) {
            errors.vehicle_type = "Vehicle type is required!";
        }

        if (!formData?.base_url?.trim()) {
            errors.base_url = "Base url is required!";
        }

        if (!formData?.payload?.trim()) {
            errors.payload = "Payload is required!";
        }

        return errors;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const checkError = validate(formData);
        if (Object.values(checkError)?.length > 0) {
            setFormErrors(checkError);
        } else {
            setFormErrors({});
            dispatch(setError([]));
            editTrackerInfo({ id: id, ...formData } as any);
        }
    };

    if ((editResult.isSuccess && editResult.data.status === 200)) {
        handleModel(false)
    }

    return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className='form-outers karuModal'>
                        <Typography id="transition-modal-title" variant='h5' className='inner-headings' component="h2">
                            Edit Tracker Detail
                        </Typography>
                        <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />
                        <Box className="modalBody">
                            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                            <form onSubmit={handleSubmit} >
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="authorization_id"
                                            label="Authorization ID"
                                            name="authorization_id"
                                            type="text"
                                            size='small'
                                            autoComplete="authorization_id"
                                            value={formData.authorization_id}
                                            onChange={handleChange}
                                            error={!!formErrors.authorization_id}
                                            helperText={formErrors.authorization_id}
                                        />
                                        <Error current_key="authorization_id" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="base_url"
                                            label="Base Url"
                                            name="base_url"
                                            type="text"
                                            size='small'
                                            autoComplete="base_url"
                                            value={formData.base_url}
                                            onChange={handleChange}
                                            error={!!formErrors.base_url}
                                            helperText={formErrors.base_url}
                                        />
                                        <Error current_key="base_url" />
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Autocomplete
                                        id="vehicle_type"
                                        options={VehicleTypeList}
                                        disableClearable={true}
                                        size='small'
                                        onChange={(event, newValue: SelectedVehicleType) => {
                                            handleVehicleType(newValue)
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#0a243d" }}>{children}</Paper>
                                        )}
                                        value={formData.selected_vehicle_type}
                                        autoHighlight
                                        getOptionLabel={(option: any) => option?.name}
                                        renderOption={(props, option: any) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choose A Vehicle Type"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', 
                                                }}
                                                required
                                                error={!!formErrors.vehicle_type}
                                                helperText={formErrors.vehicle_type}
                                            />
                                        )}
                                    />
                                    <Error current_key="vehicle_type" />
                                </Grid>
                            
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="payload"
                                            label="Payload"
                                            name="payload"
                                            type="text"
                                            size='small'
                                            autoComplete="payload"
                                            value={formData.payload}
                                            onChange={handleChange}
                                            error={!!formErrors.payload}
                                            helperText={formErrors.payload}
                                        />
                                        <Error current_key="payload" />
                                    </Grid>                              
                                    
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="save-btn"
                                            style={{ marginRight: '10px' }}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className="close-btn"
                                            onClick={() => handleModel(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
    );
}
export default memo(TrackerAddEditModal)
