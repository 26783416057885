import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
    Button,
    Paper,
    Snackbar,
    StyledEngineProvider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import { TableBodyElement } from '../../../components/TableBody/TableBodyElement';
import { TableHeadElement } from '../../../components/TableBody/TableHeadElement';
import { ColumnsType, Order, PageMeta, SortingData } from '../../../types/CommonTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmBox from '../../../components/ConfirmBox/ConfirmBox';
import { useDeleteFuelEntriesMutation, useGetMachineInfoByIdQuery, useMachineFuelEntriesListQuery } from '../../../redux-services';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite-no-reset.min.css";
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import AddEditFuel from './AddEditFuel';


const tableColumns: ColumnsType[] = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "user_name",
        sort: true,
        label: "User Name",
    },
    {
        id: "machine_name",
        sort: true,
        label: "Machinery Name",
    },
    {
        id: "fuel_quantity",
        sort: false,
        label: "Fuel Quantity"
    },
    {
        id: "fuel_receipt_number",
        sort: true,
        label: "Receipt Number"
    },
    {
        id: 'createdAt',
        sort: true,
        label: "Date"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];

const predefinedRanges = [
    {
        label: 'Today',
        value: [new Date(), new Date()],
        placement: 'left'
    },
    {
        label: 'Yesterday',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left'
    },
    {
        label: 'This week',
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
        placement: 'left'
    },
    {
        label: 'Last 7 days',
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left'
    },
    {
        label: 'Last 30 days',
        value: [subDays(new Date(), 29), new Date()],
        placement: 'left'
    },
    {
        label: 'This month',
        value: [startOfMonth(new Date()), new Date()],
        placement: 'left'
    },
    {
        label: 'Last month',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left'
    },
    {
        label: 'This year',
        value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
        placement: 'left'
    },
    {
        label: 'Last year',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
        placement: 'left'
    },
    {
        label: 'All time',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
        placement: 'left'
    },
    {
        label: 'Last week',
        closeOverlay: false,
        value: (value: any) => {
            const [start = new Date()] = value || [];
            return [
                addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
            ];
        },
        appearance: 'default'
    },
    {
        label: 'Next week',
        closeOverlay: false,
        value: (value: any) => {
            const [start = new Date()] = value || [];
            return [
                addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
            ];
        },
        appearance: 'default'
    }
];


let initialOrder: string = process.env.REACT_APP_ORDER as string;

const MachineFuelEntries = () => {

    const initialState: PageMeta = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE),
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };
    let navigate = useNavigate();
    const { id, siteId } = useParams();

    let query = useLocation();
    const [page, setPage] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<keyof SortingData>(
        process.env.REACT_APP_ORDER_BY as keyof SortingData
    );
    const [order, setOrder] = useState<Order>(initialOrder as Order);
    const [search, setSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        initialState.per_page as number
    );
    const [fuelList, setFuelList] = useState<PageMeta>(initialState);
    const [dateRange, setDateRange] = useState({
        startDate: "",
        endDate: "",
    })
    const [isClose, setIsClose] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [fuelEntryEditId, setFuelEntryEditId] = useState<string>('');
    const [Id, setId] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const DateFormat = useCallback((dateString: any) => {
        const year = dateString.getFullYear();
        const month = String(dateString.getMonth() + 1).padStart(2, '0');
        const day = String(dateString.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }, []);

    const { data } = useMachineFuelEntriesListQuery({
        per_page: fuelList.per_page ?? rowsPerPage,
        page: query.state ?? fuelList.page,
        order: fuelList.order ?? order,
        order_by: fuelList.order_by ?? orderBy,
        searchText: fuelList.searchText ?? search,
        startDate: dateRange.startDate ? dateRange.startDate : "",
        endDate: dateRange.endDate ? dateRange.endDate : "",
        id: id ?? '',
        site_id: siteId ?? '',
    });
   
    const { data: getData } = useGetMachineInfoByIdQuery(id, {
        skip: !id,
    });

    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response
            let deactivateStatus = data?.siteData[0]?.is_deactivate
            setIsButtonDisabled(deactivateStatus);
            setIsActive(data.is_active)
        }
    }, [getData]);

    const [deleteShiftLogs] = useDeleteFuelEntriesMutation();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setFuelList({
            ...fuelList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (
        event: React.MouseEvent<unknown>,
        property: keyof SortingData
    ) => {
        if (property !== ("action" as keyof SortingData)) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        setFuelList({ ...fuelList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setFuelList({
            ...fuelList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
        setPage(1);
    };

    const searchHandler = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
            setFuelList({
                page: 1,
                per_page: fuelList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, []);

    const confirmBoxClose = (res: boolean) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteShiftLogs(Id);
        } else {
            setIsClose(res);
        }
    };

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setDateRange({ ...dateRange, startDate: DateFormat(date[0]), endDate: DateFormat(date[1]) })
        } else {
            setDateRange({ ...dateRange, startDate: '', endDate: '' })
        }
    };
    const handleShowMessage = () => {
        setSnackbarOpen(true);
        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(data?.response?.data || []);

    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setSearchTerm(value);

        const filtered = data?.response?.data?.filter(
            (item: any) =>
                item.user_name.toLowerCase().includes(value.toLowerCase()) ||
                item.fuel_receipt_number.toString().includes(value)
        );
        setFilteredData(filtered);
    };

    useEffect(() => {
        setFilteredData(data?.response?.data || []);
    }, [data]);

    return (
        <>
            <div>
                <Paper className="form-outers">
                    <Toolbar>
                        <Typography
                            sx={{ flex: '1 1 40%' }}
                            variant="h5"
                            id="tableTitle"
                            component="div"
                            className="inner-headings"
                        >
                            Fuel Entries List
                        </Typography>
                        <div className="flex-boxinner worklogs">
                            <TextField
                                size="small"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ marginRight: '10px' }}
                            />
                            <Typography
                                sx={{ flex: '1 1 auto' }}
                                className="datepicker-outer"
                            >
                                <DateRangePicker
                                    ranges={predefinedRanges as any}
                                    placeholder="Select Date Range"
                                    showOneCalendar
                                    onChange={handleDateChange}
                                    className="dateselecttype"
                                    style={{ width: "100%" }}
                                />
                            </Typography>
                            {isButtonDisabled ? null : (
                            <Tooltip title="Add Fuel Entry" arrow className="me-1 mt-1">
                                <Button className="secondary-btn" onClick={() => {
                                    setShowModal(true);
                                    handleShowMessage();
                                }} type="submit" variant="contained" color="primary">Add Fuel Entry</Button>
                            </Tooltip>)}
                            <Snackbar
                                open={snackbarOpen && !isActive}
                                autoHideDuration={3000}
                                onClose={() => setSnackbarOpen(false)}
                                message="Please activate this machine to add Fuel logs."
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                style={{ marginTop: '140px' }}
                            />
                        </div>
                    </Toolbar>
                    <StyledEngineProvider>
                        <TableContainer component={Paper} className="form-outers">
                            <Table
                                sx={{ minWidth: 650 }}
                                size="small" aria-label="a dense table"
                                className="table-outers"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableHeadElement
                                            setHead={tableColumns ? tableColumns : []}
                                            order={order}
                                            orderBy={orderBy}
                                            sortFn={(event, id: string) =>
                                                createSortHandler(event, id as keyof SortingData)
                                            }
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableBodyElement
                                        selectedColumns={[
                                            "user_name",
                                            "machine_name",
                                            "fuel_quantity",
                                            "fuel_receipt_number",
                                            "createdAt"
                                        ]}
                                        setData={filteredData}
                                        editFn={(id) => {
                                            setFuelEntryEditId(id);
                                            setShowModal(true);
                                        }}
                                        deleteFn={(id) => {
                                            setIsClose(true);
                                            setId(id);
                                        }}
                                        pageData={{
                                            limit:
                                                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                            page: data?.response.page as number,
                                        }}
                                    />
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={filteredData?.length ?? 0}
                                page={data?.response.page ?? 0}
                                rowsPerPageOptions={[20, 50, 100]}
                                onPageChange={handleChangePage}
                                rowsPerPage={
                                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </StyledEngineProvider>
                </Paper>
                <ConfirmBox
                    title="Fuel Entry Delete Confirmation"
                    message="Are you sure, you want to delete this Fuel Entry?"
                    open={isClose}
                    onClose={confirmBoxClose}
                />
            </div>

            {showModal && isActive &&
                <AddEditFuel id={fuelEntryEditId} showModal={showModal} setShowModal={setShowModal} setFuelEntryEditId={setFuelEntryEditId} machineId={id} siteId={siteId ?? ''} />
            }
        </>
    )
}

export default memo(MachineFuelEntries);