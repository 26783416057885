import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fade,
  Modal,
  Backdrop,
  Divider,
  Typography,
  Autocomplete,
  Checkbox,
  Alert,
  Paper,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setError,
  useCreateFuelEntriesMutation,
  useEditFuelEntriesMutation,
  useGetFuelEntriesByIdQuery,
  useGetUserDataDetailQuery,
  useFindPreviousFuelQuery,
  useGetFuelTypeListWithQtyQuery,
  useGetMachineInfoByIdQuery,
  useGetMachineryNamesBySiteIdQuery,
} from "../../../redux-services";
import Error from "../../../components/ErrorBox/Error";
import ClearIcon from "@mui/icons-material/Clear";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DistanceTravel, FuelType } from "../../../ constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export interface UserState {
  machine_id: any;
  operator_id: any;
  distance_reading_type: string;
  fuel_detail: any;
  fuel_entry_time: string | Record<string, any>;
  fuel_type: any;
  images: any[];
  image_url: any[];
  urls?: any[];
  deleted_images?: any;
  fuel_receipt_number: any;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddEditFuel: React.FC<{
  id: string;
  showModal: boolean;
  setShowModal: any;
  setFuelEntryEditId: any;
  machineId: any;
  siteId: string;
}> = ({
  id,
  showModal,
  setShowModal,
  setFuelEntryEditId,
  machineId,
  siteId,
}) => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      border: "1px solid #000",
      boxShadow: 24,
      borderRadius: 2,
      p: 2,
    };

    const intialState = {
      machine_id: { _id: "", machine_type: "", number_plate: "" },
      operator_id: "",
      fuel_entry_time: "",
      distance_reading_type: "",
      fuel_detail: [],
      fuel_type: [],
      images: [],
      image_url: [],
      urls: [],
      deleted_images: [],
      fuel_receipt_number: "",
    };
    let dispatch = useDispatch();

    const { data: MachineInfoData } = useGetMachineInfoByIdQuery(machineId, {
      skip: !machineId,
    });

    const {
      isSuccess,
      isLoading,
      data: FuelTypeList,
    } = useGetFuelTypeListWithQtyQuery(
      { machineId, siteId }
    );


    const [formData, setFormData] = useState<UserState>(intialState);
    const [submitted, setSubmitted] = useState(false);
    const [errorValidation, setErrorValidation] = useState({
      message: [],
      status: false,
    });
    const [isSetPreviousReading, setIsSetPreviousReading] = useState(false);
    const [fuelTypeList, setFuelTypeList] = useState([]);
    const [createFuelEntries, result] = useCreateFuelEntriesMutation();
    const [editFuelEntries, editResult] = useEditFuelEntriesMutation();
    const { data: FuelData } = useGetFuelEntriesByIdQuery(id, {
      skip: !id || id === "",
    });

    const MachineryData = useGetMachineryNamesBySiteIdQuery(siteId);

    const UserData = useGetUserDataDetailQuery(siteId);

    const { data: PreviousData } = useFindPreviousFuelQuery(
      formData?.machine_id?._id,
      { skip: formData?.machine_id?._id === "" }
    );

    useEffect(() => {
      if (formData?.operator_id === "") {
        if (UserData?.data?.status === 200 && FuelTypeList?.status === 200) {
          if (FuelTypeList?.response?.userId !== null) {
            let userdata = UserData?.data?.response.find(
              (item: any) => item?._id === FuelTypeList?.response?.userId
            );
            setFormData({ ...formData, operator_id: userdata });
          }
        }
      }
    }, [formData, UserData, FuelTypeList]);

    useEffect(() => {
      if (FuelData?.status === 200) {
        const fuelDataResponse = FuelData?.response;
        let data: any = fuelDataResponse;
        const unixTimestamp1 = data?.fuel_entry_time;
        const fuelEntryTime = dayjs(new Date(unixTimestamp1));
        let fuelType: any[] = [];
        if (FuelTypeList?.status === 200 && isSuccess) {
          const fuelDetail = data?.fuel_detail || [];
          for (let items of fuelDetail) {
            fuelType.push(
              FuelTypeList?.response?.fuelTypeList?.find(
                (item: {
                  _id: string;
                  company_name: string;
                  fuel_type: number;
                }) => item?._id === items?.fuel_type_id
              )
            );
          }

          setFormData((data1: any) => ({
            ...data1,
            machine_id: data?.machineData[0] ?? "",
            operator_id: data?.userData[0] ?? "",
            fuel_entry_time: fuelEntryTime,
            fuel_receipt_number: data?.fuel_receipt_number,
            distance_reading_type: String(data?.distance_reading_type),
            fuel_type: fuelType,
            fuel_detail: data?.fuel_detail,
            images:
              data?.images?.length > 0
                ? data.images.map((image: any) => image.path)
                : [],
            image_url: data?.encodedUrl?.length > 0 ? data.encodedUrl : [],
            urls:
              data.images?.length > 0
                ? data?.images.map((image: any) => image.fullUrl)
                : [],
          }));
          if (!id) {
            setIsSetPreviousReading(true);
          }
        }
      }
      return () => {
        dispatch(setError([]));
        setErrorValidation({
          status: false,
          message: [],
        });
      };
    }, [FuelData, FuelTypeList, isLoading, isSuccess]);

    useEffect(() => {
      if (
        MachineryData?.data !== undefined &&
        MachineryData?.data?.response?.length > 0
      ) {
        let filteredData = MachineryData?.data?.response?.find(
          (item: { _id: string; machine_type: string; number_plate: string }) =>
            item?._id === machineId
        );
        if (filteredData !== null) {
          let DistanceReadingType = "";
          // let DistanceReadingType = filteredData.measurement_unit;
          let FuelTypes = formData?.fuel_type;
          if (["Tipper", "Camper"].includes(filteredData?.machine_type)) {
            DistanceReadingType = String(DistanceTravel["Km"]);
          } else {
            DistanceReadingType = String(DistanceTravel["Hours"]);
          }
          if (FuelTypeList?.status === 200) {
            FuelTypes = FuelTypeList?.response?.fuelTypeList;
          }
          setFuelTypeList(FuelTypes);
          if (!id) {
            setFormData({
              ...formData,
              machine_id: filteredData,
              ["distance_reading_type"]: DistanceReadingType,
            });
          }
        }
      }
    }, [MachineryData, machineId, FuelTypeList]);

    const findFuelObject = (fuelData: any, fuelTypeId: string) => {
      for (const [key, value] of Object.entries(fuelData)) {
        const fuelObject = value as { fuel_type_id: string };
        if (
          fuelObject?.fuel_type_id !== null &&
          fuelObject?.fuel_type_id === fuelTypeId
        ) {
          return value;
        }
      }
      return null;
    };

    useEffect(() => {
      if (PreviousData?.status === 200) {
        const previousData = PreviousData?.response;
        if (formData?.fuel_type?.length > 0 && isSetPreviousReading && !id) {
          if (formData?.fuel_detail[0]?.previous_reading !== undefined) {
            const fuelDetailFormData = formData?.fuel_detail || [];
            let updatedFormData = [...fuelDetailFormData];

            for (let item = 0; item < formData?.fuel_type?.length; item++) {
              const fuelObject: any = findFuelObject(
                previousData,
                formData.fuel_type[item]?._id
              );
              if (
                fuelObject !== null &&
                formData?.fuel_type[item]?._id === fuelObject?.fuel_type_id
              ) {
                updatedFormData[item] = {
                  ...updatedFormData[item],
                  ["previous_reading"]: fuelObject?.current_reading ?? "",
                };
              }
            }
            setFormData({ ...formData, fuel_detail: updatedFormData });
          } else {
            setFormData({
              ...formData,
              fuel_detail: [
                {
                  fuel_type: "",
                  fuel_quantity: "",
                  current_reading: "",
                  previous_reading: "",
                  average: 0,
                },
              ],
            });
          }
          setIsSetPreviousReading(true);
        }
      }
    }, [PreviousData, formData.fuel_type, isSetPreviousReading]);

    useEffect(() => {
      if (result?.isSuccess && result?.data?.status === 400) {
        setErrorValidation({ message: result?.data?.error, status: true });
      }
    }, [result]);

    useEffect(() => {
      if (editResult?.isSuccess && editResult?.data?.status === 400) {
        setErrorValidation({ message: editResult?.data?.error, status: true });
      }
    }, [editResult]);

    if (result.isSuccess && result.data.status === 200) {
      setShowModal(false);
      setFuelEntryEditId("");
    }

    if (editResult.isSuccess && editResult.data.status === 200) {
      setShowModal(false);
      setFuelEntryEditId("");
    }

    const selectUserChange = (event: any) => {
      setFormData({ ...formData, operator_id: event.target.value as string });
    };

    const handleDateTimeChange = (fieldName: string, value: any) => {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: new Date(value).valueOf(),
      }));
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();
      dispatch(setError([]));
      setSubmitted(true);
      let FuelDetail = [];
      for (let type of formData.fuel_type) {
        FuelDetail.push(
          formData.fuel_detail.find(
            (item: { fuel_type_id: string }) => item?.fuel_type_id === type?._id
          )
        );
      }
      let data = new FormData();
      for (const item of formData.images) {
        data.append("images", item);
      }
      if (id) {
        for (const item of formData.deleted_images) {
          data.append("deleted_images", item);
        }
      }
      let date_time: any;
      if (typeof formData.fuel_entry_time === "object") {
        date_time = formData.fuel_entry_time.valueOf();
      } else {
        date_time = formData.fuel_entry_time;
      }
      data.append("operator_id", formData.operator_id?._id);
      data.append("fuel_entry_time", date_time.toString());
      data.append("machine_id", formData.machine_id?._id);
      data.append("distance_reading_type", formData.distance_reading_type);
      data.append("fuel_detail", JSON.stringify(FuelDetail));
      data.append("id", id ?? null);
      data.append(
        "organization_name",
        MachineInfoData?.response.organization_name
      );
      data.append("fuel_receipt_number", formData.fuel_receipt_number);
      data.append("site_id", siteId);

      id ? editFuelEntries(data) : createFuelEntries(data);
    };

    const SelectFuelType = (event: any, newValue: any) => {
      if (newValue?.length === 0) {
        setFormData({
          ...formData,
          ["fuel_type"]: newValue,
          fuel_detail: [],
        });
      } else {
        if (formData?.fuel_type?.length < newValue?.length) {
          setFormData({
            ...formData,
            ["fuel_type"]: newValue,
            fuel_detail: [
              ...formData.fuel_detail,
              {
                fuel_type: "",
                fuel_quantity: "",
                current_reading: "",
                previous_reading: "",
                average: 0,
              },
            ],
          });
          setIsSetPreviousReading(true);
        } else {
          setFormData({
            ...formData,
            ["fuel_type"]: newValue,
          });
        }
      }
    };

    const handleChangeImage = (event: any) => {
      let setFileUrl: string[] = [];
      let file = Array.from(event.target.files);
      for (const element of event.target.files) {
        setFileUrl.push(URL.createObjectURL(element));
      }
      setFormData({
        ...formData,
        images: [...formData.images, ...file],
        image_url: [...formData.image_url, ...setFileUrl],
      });
      const thumbnailElement = document.getElementById(
        "images"
      ) as HTMLInputElement | null;

      if (thumbnailElement) {
        thumbnailElement.value = "";
      }
    };

    const handleRemoveImage = useCallback(
      (index: number) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);

        let deletedImgArr = [];
        if (formData.urls?.length) {
          deletedImgArr.push(formData.urls[index]);
        }

        const updatedFiles = [...formData.image_url];
        updatedFiles.splice(index, 1);
        setFormData({
          ...formData,
          images: updatedImages,
          image_url: updatedFiles,
          deleted_images: [...formData.deleted_images, ...deletedImgArr],
        });
      },
      [formData]
    );

    const handleFuelTypeEntry = (
      event: React.ChangeEvent<any>,
      item: any,
      index: number
    ) => {
      const { name, value } = event.target;
      let updatedFuelDetail = formData.fuel_detail.map((items: any, idx: any) => {
        if (idx === index) {
          return {
            ...items,
            [name]: Number(value),
            fuel_type: item?.fuel_type,
            fuel_type_id: item?._id,
          };
        }
        return items;
      });

      if (updatedFuelDetail[index].fuel_quantity !== "") {
        let Previous = parseFloat(updatedFuelDetail[index]?.previous_reading);
        let Current = parseFloat(updatedFuelDetail[index]?.current_reading);
        if (updatedFuelDetail[index]?.previous_reading === "") {
          Previous = 0;
        }
        if (updatedFuelDetail[index]?.current_reading === "") {
          Current = 0;
        }
        let FuelQty = parseFloat(updatedFuelDetail[index]?.fuel_quantity);
        let AverageValue = Math.abs(Current - Previous);

        if (FuelQty !== 0) {
          updatedFuelDetail = updatedFuelDetail.map((items: any, idx: any) => {
            if (idx === index) {
              return {
                ...items,
                average: parseFloat((AverageValue / FuelQty).toFixed(2)),
              };
            }
            return items;
          });
        } else {
          updatedFuelDetail = updatedFuelDetail.map((items: any, idx: any) => {
            if (idx === index) {
              return {
                ...items,
                average: 0,
              };
            }
            return items;
          });
        }
      }
      setFormData({ ...formData, fuel_detail: updatedFuelDetail });
    };

    const handleRemoveError = (index: number) => {
      let text = errorValidation.message;
      if (text?.length > 0) {
        text = [...text.slice(0, index), ...text.slice(index + 1)];
      }
      if (text?.length > 0) {
        setErrorValidation({ ...errorValidation, message: text });
      } else {
        setErrorValidation({ message: text, status: false });
      }
    };

    const userData = UserData?.data?.response;
    const sortedUserData = [...(userData || [])]?.sort((a: any, b: any) => {
      const firstNameComparison = a.firstname.localeCompare(b.firstname);

      if (firstNameComparison === 0) {
        return a.lastname.localeCompare(b.lastname);
      }
      return firstNameComparison;
    });

    return (
      <Container maxWidth={false} className="p-0">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showModal}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={showModal}>
            <Box sx={style} className="form-outers karuModal">
              <Typography
                id="transition-modal-title"
                variant="h5"
                className="inner-headings"
                component="h2"
              >
                {id ? "Edit Fuel Entry" : "Add Fuel Entry"}
              </Typography>
              <HighlightOffIcon
                className="closeicon"
                onClick={() => {
                  setShowModal(false);
                  setFuelEntryEditId("");
                }}
              />

              <Box className="modalBody">
                {errorValidation?.status &&
                  errorValidation?.message?.map((msg: string, index) => (
                    <Alert
                      severity="error"
                      onClose={() => handleRemoveError(index)}
                      sx={{ marginTop: 1 }}
                    >
                      {msg}
                    </Alert>
                  ))}
                <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className="custom-datepicker"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <DateTimePicker
                          label="Fuel Entry Date Time *"
                          name="fuel_entry_time"
                          disableFuture={true}
                          maxDate={dayjs()}
                          slotProps={{
                            textField: {
                              error: !formData.fuel_entry_time,
                              helperText:
                                submitted && !formData.fuel_entry_time
                                  ? "Fuel Entry time is required"
                                  : "",
                            },
                          }}
                          value={formData.fuel_entry_time}
                          onChange={(newValue: any) =>
                            handleDateTimeChange("fuel_entry_time", newValue)
                          }
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="fuel_receipt_number"
                        label="Receipt Number"
                        name="fuel_receipt_number"
                        size="small"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 0,
                          step: 0.01,
                        }}
                        autoComplete="fuel_receipt_number"
                        value={formData.fuel_receipt_number}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            fuel_receipt_number: e.target.value,
                          })
                        }
                        disabled={!!FuelData?.response?.fuel_receipt_number}
                      />
                      <Error current_key="fuel_receipt_number" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Employee Names *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData?.operator_id}
                            label="Employee Name"
                            required
                            onChange={selectUserChange}
                            renderValue={() =>
                              formData.operator_id?.firstname +
                              ` ${formData.operator_id?.lastname} (${formData.operator_id?.ccode +
                              "-" +
                              formData.operator_id?.contact_no
                              })`
                            }
                          >
                            {sortedUserData?.map((item: any, index: number) => (
                              <MenuItem
                                value={item}
                                key={item}
                                selected={
                                  formData?.operator_id?._id === item?._id
                                }
                              >
                                {item?.firstname +
                                  ` ${item?.lastname} (${item?.ccode + "-" + item?.contact_no
                                  })`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Error current_key="operator_id" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={fuelTypeList as any}
                        value={formData.fuel_type}
                        onChange={SelectFuelType}
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#0a243d" }}>
                            {children}
                          </Paper>
                        )}
                        getOptionLabel={(option) =>
                          FuelType[option?.fuel_type] +
                          " (" +
                          option?.company_name +
                          ")"
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={
                                formData.fuel_type.find(
                                  (item: { _id: string }) =>
                                    item?._id === option?._id
                                ) ?? selected
                              }
                            />
                            {FuelType[option?.fuel_type] +
                              " (" +
                              option?.company_name +
                              ")"}
                          </li>
                        )}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Fuel Types"
                            placeholder={
                              formData.fuel_type?.length === 0
                                ? "Select Fuel Type"
                                : ""
                            }
                            required={formData.fuel_type?.length === 0}
                          />
                        )}
                      />
                    </Grid>

                    {formData.fuel_type?.length > 0 &&
                      formData?.fuel_type?.map((item: any, index: number) => (
                        <>
                          <Grid item xs={12} md={12}>
                            <Typography
                              id="transition-modal-title"
                              variant="h5"
                              className="inner-headings"
                            >
                              {FuelType[item?.fuel_type] +
                                " (" +
                                item?.company_name +
                                ")"}{" "}
                              Entry
                              <Box
                                sx={{
                                  backgroundColor: "black",
                                  color: "yellow",
                                  padding: "4px 8px",
                                  borderRadius: "4px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                }}
                              >
                                Fuel Remaining: {item?.quantity ?? "N/A"}
                              </Box>
                            </Typography>
                          </Grid>
                          <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="fuel_quantity"
                              label="Fuel Quantity"
                              name="fuel_quantity"
                              size="small"
                              required
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: 0,
                                step: 0.01,
                              }}
                              autoComplete="fuel_quantity"
                              value={formData.fuel_detail[index]?.fuel_quantity}
                              onChange={(e) =>
                                handleFuelTypeEntry(e, item, index)
                              }
                            />
                            <Error current_key="fuel_quantity" />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="previous_reading"
                              label="Previous Reading"
                              name="previous_reading"
                              size="small"
                              required
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                inputMode: 'decimal',
                                pattern: '[0-9]*[.,]?[0-9]*'
                              }}
                              autoComplete="previous_reading"
                              value={
                                formData.fuel_detail[index]?.previous_reading
                              }
                              onChange={(e) =>
                                handleFuelTypeEntry(e, item, index)
                              }
                            />
                            <Error current_key="previous_reading" />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="current_reading"
                              label="Current Reading"
                              name="current_reading"
                              size="small"
                              required
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                inputMode: 'decimal',
                                pattern: '[0-9]*[.,]?[0-9]*'
                              }}
                              autoComplete="current_reading"
                              value={formData.fuel_detail[index]?.current_reading}
                              onChange={(e) =>
                                handleFuelTypeEntry(e, item, index)
                              }
                            />
                            <Error current_key="current_reading" />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="average"
                              label="Average"
                              name="average"
                              size="small"
                              disabled={true}
                              type="text"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: 0,
                              }}
                              autoComplete="average"
                              value={formData.fuel_detail[index]?.average}
                              onChange={(e) =>
                                handleFuelTypeEntry(e, item, index)
                              }
                            />
                            <Error current_key="average" />
                          </Grid>
                        </>
                      ))}
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="images"
                          name="images"
                          label="Fuel Entries Images"
                          type="file"
                          className="accessory_img"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ accept: "image/*", multiple: true }}
                          autoComplete="images"
                          onChange={handleChangeImage}
                        />
                        <Error current_key="images" />
                      </Grid>
                      <Grid item xs={12} className="imageDiv">
                        {formData?.image_url?.length > 0
                          ? formData.image_url.map((image: string, index: number) => (
                            <div className="company-logo" key={image}>
                              <img
                                src={image}
                                alt={`fuel_entries-img-${index}`}
                                className="preview-img"
                              />
                              <button
                                className="closeimg"
                                style={{
                                  display: "flex",
                                  border: "none",
                                  background: "none",
                                }} onClick={() => handleRemoveImage(index)}
                                title="Remove Image"
                              >
                                <ClearIcon />
                              </button>
                            </div>
                          ))
                          : ""}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="save-btn"
                        onSubmit={handleSubmit}
                        style={{ marginRight: "10px" }}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="close-btn"
                        onClick={() => {
                          setShowModal(false);
                          setFuelEntryEditId("");
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Container>
    );
  };

export default memo(AddEditFuel);