import React, { useEffect, useState, FC, useCallback } from 'react';
import {
    Autocomplete,
    Divider,
    Fade,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    Box,
    Modal,
    Backdrop,
} from '@mui/material';
import {  useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    setError,
    useCreateTrackerInfoMutation,
    useEditTrackerInfoMutation,
    useGetTrackerInfoByIdQuery,
    useGetUserDetailQuery,
    useOrganizationListQuery,
} from '../../../../redux-services';
import Error from '../../../../components/ErrorBox/Error';
import { VehicleTypeList } from '../../../../ constants';
import { VehicleType} from '../../../../types/CommonTypes';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
};

export interface TrackerInfoState {
    authorization_id: any;
    organization_id: any;
    vehicle_type?: number;
    selected_vehicle_type?: SelectedVehicleType;
    payload: string;
    base_url: string;
}

export interface SelectedVehicleType {
    name: string;
    value: number;
}

interface FormErrors {
    authorization_id?: string;
    organization_id?: string;
    vehicle_type?: string;
    payload?: string;
    base_url?: string;
}

const AddEditTrackerInfo: FC<{ open: boolean; handleModel: any; editId?: string; siteOrgDetail: any, trackerData: any }> = ({
    open,
    handleModel,
    editId,
    siteOrgDetail,
    trackerData
}) => {
    const { id } = useParams();

    const intialState = {
        authorization_id: '',
        organization_id: '',
        vehicle_type: VehicleTypeList[0].value,
        base_url: '',
        selected_vehicle_type: { name: VehicleTypeList[0].name, value: VehicleTypeList[0].value },
        payload: '',
    };
    const dispatch = useDispatch();
    const [orgId, setOrgId] = useState<string | null>(null);
    const [formData, setFormData] = useState<TrackerInfoState>(intialState);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [createTrackerInfo, result] = useCreateTrackerInfoMutation();
    const [editTrackerInfo, editResult] = useEditTrackerInfoMutation();

    const { data: userDetail } = useGetUserDetailQuery();
    const { data: organizationData } = useOrganizationListQuery({
        organization_id: '',
    });

    const orgData = userDetail?.response;
    const org_name: any = organizationData?.response?.data?.map((data: any) => data?.organization_name) || [];

    const [selectedOrg, setSelectedOrg] = useState('');
    const { data: getTrackerDataById } = useGetTrackerInfoByIdQuery(editId, {
        skip: !editId,
    });
   
    const orgIdValidation = useCallback(
        (org_id: any) => {
            setFormData({ ...formData, organization_id: org_id });

            const updatedFormData = { ...formData, organization_id: org_id };
            const errors = validate(updatedFormData);

            setFormErrors((prevErrors) => ({
                ...prevErrors,
                organization_id: errors['organization_id'],
            }));
        }, [formData]
    );

    useEffect(() => {
        if (!orgData?.organization_id && id) {
            setOrgId(siteOrgDetail?.response?.organization_id[0]._id);
        } else {
            setOrgId(orgData?.organization_id);
        }
        if (orgId) {
            orgIdValidation(orgId);
        }
    }, [formData, orgId, orgData, id, siteOrgDetail]);

    useEffect(() => {
        if (getTrackerDataById?.status === 200) {
            setFormData({
                ...formData,
                authorization_id: getTrackerDataById?.response?.authorization_id ?? '',
                base_url: getTrackerDataById?.response?.base_url ?? '',
                vehicle_type: getTrackerDataById?.response?.vehicle_type ?? '',
                organization_id: getTrackerDataById?.response?.organization_id ?? '',
                selected_vehicle_type: VehicleTypeList.find((item: VehicleType) => item.value === getTrackerDataById?.response?.vehicle_type),
                payload: getTrackerDataById?.response?.payload ?? '',
            });
        }

        const orgName = organizationData?.response?.data?.filter((data: any) => {
            if (data?._id === getTrackerDataById?.response?.organization_id) {
                return data?.organization_name;
            }
        })
        if (orgName) {
            setSelectedOrg(orgName[0]?.organization_name);
        }
    }, [getTrackerDataById]);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
            e.preventDefault();
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });

            const updatedFormData = { ...formData, [name]: value };
            const errors = validate(updatedFormData);

            setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errors[name as keyof FormErrors],
            }));
        },
        [formData]
    );

    const handleOrgChange = useCallback(
        (event: any, newValue: any) => {
            setSelectedOrg(newValue);
            const org_id = organizationData?.response?.data?.find((data: any) => data?.organization_name === newValue)?._id;
            orgIdValidation(org_id);
        },
        [organizationData?.response?.data, formData]
    );

    const handleVehicleType = useCallback(
        (data: SelectedVehicleType) => {
            setFormData({ ...formData, vehicle_type: data.value, selected_vehicle_type: data });
            const updatedFormData = { ...formData, vehicle_type: data.value, selected_vehicle_type: data };
            const errors = validate(updatedFormData);

            setFormErrors((prevErrors) => ({
            ...prevErrors,
            'vehicle_type': errors['vehicle_type'],
            }));
        },
        [formData]
    );

    const validate = (formData: TrackerInfoState) => {
        const errors: Partial<FormErrors> = {};

        if (!formData?.organization_id?.trim()) {
        errors.organization_id = "Organisation Name is required!";
        }

        if (!formData?.authorization_id?.trim()) {
        errors.authorization_id = "Authorization Id is required!";
        }

        if (!formData?.vehicle_type) {
        errors.vehicle_type = "Vehicle type is required!";
        }

        if (!formData?.base_url?.trim()) {
        errors.base_url = "Base url is required!";
        }

        if (!formData?.payload?.trim()) {
        errors.payload = "Payload is required!";
        }

        return errors;
    };
    
    const handleSubmit = (e: any) => {
        e.preventDefault();
        const checkError = validate(formData);
        if (Object.values(checkError)?.length > 0) {
            setFormErrors(checkError);
        } else {
            setFormErrors({});
            dispatch(setError([]));
            editId
                ? editTrackerInfo({ id: editId, ...formData } as any)
                : createTrackerInfo({ ...formData } as any);
        }
    };

    const url = window.location.href;
    if (
        (editResult.isSuccess && editResult.data.status === 200) ||
        (result.isSuccess && result.data.status === 200)
    ) {
        handleModel(false);
    }

    return (
        <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
            <Fade in={open}>
                <Box sx={style} className="form-outers karuModal">
                    <Typography id="transition-modal-title" variant="h5" className="inner-headings" component="h2">
                        {editId ? 'Edit' : 'Add'} Tracker Details
                    </Typography>
                    <HighlightOffIcon className="closeicon" onClick={() => handleModel(false)} />
                    <Box className="modalBody">
                        <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                 <Grid item xs={6}>
                                    {!orgData?.organization_id && !id ?
                                        <><Autocomplete
                                            id="organization_id"
                                            options={org_name}
                                            size="small"
                                            value={selectedOrg}
                                            onChange={handleOrgChange}
                                            autoHighlight
                                            getOptionLabel={(option) => option}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option}
                                                </Box>
                                            )}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#0a243d" }}>{children}</Paper>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Choose an Organization"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "off",
                                                    }}
                                                    error={!!formErrors.organization_id}
                                                    helperText={formErrors.organization_id}
                                                    required
                                                />
                                            )}
                                        /></>
                                        :
                                        <><TextField
                                            required
                                            fullWidth
                                            id="organization_id"
                                            label="Organization Name"
                                            name="organization_id"
                                            type="text"
                                            size="small"
                                            autoComplete="off"
                                            
                                            value={!orgData?.organization_id && id ? siteOrgDetail?.response?.organization_id[0].organization_name : orgData?.organization_name}
                                            disabled={true}
                                            error={!!formErrors.organization_id}
                                            helperText={formErrors.organization_id}
                                        /></>
                                    }
                                        <Error current_key="organization_id" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        id="vehicle_type"
                                        disableClearable={true}
                                        options={VehicleTypeList}
                                        size="small"
                                        onChange={(event, newValue: SelectedVehicleType) => {
                                            handleVehicleType(newValue);
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#0a243d" }}>{children}</Paper>
                                        )}
                                        value={formData.selected_vehicle_type}
                                        autoHighlight
                                        getOptionLabel={(option: any) => option?.name}
                                        renderOption={(props, option: any) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choose A Vehicle Type"
                                                
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "off", 
                                                }}
                                                required
                                                error={!!formErrors.vehicle_type}
                                                helperText={formErrors.vehicle_type}
                                            />
                                        )}
                                        disabled={orgData?.username==="Super Admin" ? (selectedOrg ? false: (url.includes("admin/organization/sites") ? false: true)) : (url.includes("admin/organization/sites") || (url.includes("admin/tracker")) ? false: true)}
                                    />
                                    <Error current_key="vehicle_type" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="authorization_id"
                                        label="Authorization_id"
                                        name="authorization_id"
                                        type="text"
                                        size="small"
                                        autoComplete="off"
                                        value={formData.authorization_id}
                                        onChange={handleChange}
                                        error={!!formErrors.authorization_id}
                                        helperText={formErrors.authorization_id}
                                    />
                                    <Error current_key="authorization_id" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="base_url"
                                        label="Base Url"
                                        name="base_url"
                                        type="text"
                                        size='small'
                                        autoComplete="off"
                                        value={formData.base_url}
                                        onChange={handleChange}
                                        error={!!formErrors.base_url}
                                        helperText={formErrors.base_url}
                                    />
                                    <Error current_key="base_url" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="payload"
                                        label="Payload"
                                        name="payload"
                                        type="text"
                                        size="medium"
                                        autoComplete="off"
                                        value={formData.payload}
                                        onChange={handleChange}
                                        error={!!formErrors.payload}
                                        helperText={formErrors.payload}
                                    />
                                    <Error current_key="payload" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="save-btn"
                                        style={{ marginRight: '10px' }}
                                        disabled={Object.values(formErrors).some(
                                            (error) => !!error
                                        )}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="close-btn"
                                        onClick={() => handleModel(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default AddEditTrackerInfo;
