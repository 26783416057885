import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import Error from "../ErrorBox/Error";

interface AadharUploadComponentProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  pdfFileTypes: string[];
  wordFileTypes: string[];
}

const AadharUploadComponent: React.FC<AadharUploadComponentProps> = ({
  formData,
  setFormData,
  pdfFileTypes,
  wordFileTypes,
}) => {
const handleAadharDocumentChange = (event: any, id: string) => {
    let files = event.target.files;
    let fileType = "image";

    // Check if both front and back images are already uploaded
    if (formData.aadhar_card_url && formData.aadhar_card_back_url) {
      // Case: Two images are already uploaded, no more images can be uploaded
      if (files.length > 0) {
        alert("You can only upload 2 files for the Aadhar card (front and back).");
        // Reset the input field after handling the file
        const field = document.getElementById("aadhar_card") as HTMLInputElement | null;
        if (field) {
          field.value = "";
        }
        return;
      }
    }

    if (files.length === 2) {
      // Case: Two files uploaded
      if (
        files[0].type.startsWith("image") &&
        files[1].type.startsWith("image")
      ) {
        // If both files are images (front and back), it's valid
        const frontFile = files[0];
        const backFile = files[1];
        const frontUrl = URL.createObjectURL(frontFile);
        const backUrl = URL.createObjectURL(backFile);

        setFormData({
          ...formData,
          aadhar_card: frontFile, // Store front image
          aadhar_card_back: backFile, // Store back image
          aadhar_card_url: frontUrl, // Preview front image
          aadhar_card_back_url: backUrl, // Preview back image
          aadhar_card_type: "image", // Mark as image type
        });
      } else {
        // If either file is not an image, show error
        alert("Please upload two valid images (front and back side) for the Aadhar card.");
      }
    } else if (files.length === 1) {
      // Case: One file uploaded
      if (!formData.aadhar_card_url) {
        // First file (could be an image or document)
        if (files[0].type.startsWith("image")) {
          // First file is an image (front side)
          const frontFile = files[0];
          const frontUrl = URL.createObjectURL(frontFile);

          setFormData({
            ...formData,
            aadhar_card: frontFile, // Store front image
            aadhar_card_url: frontUrl, // Preview front image
            aadhar_card_type: "image", // Mark as image type
          });
        } else if (pdfFileTypes.includes(files[0].type) || wordFileTypes.includes(files[0].type)) {
          // First file is a document (PDF or Word)
          if (pdfFileTypes.includes(files[0].type)) {
            fileType = "pdf";
          }
          if (wordFileTypes.includes(files[0].type)) {
            fileType = "word";
          }
          const docFile = files[0];
          const docUrl = URL.createObjectURL(docFile);

          setFormData({
            ...formData,
            aadhar_card: docFile, // Store document
            aadhar_card_url: docUrl, // Preview document
            aadhar_card_type: fileType, // Mark as document type
          });
        } else {
          // Invalid file type for the first file
          alert("Please upload a valid image or document for the Aadhar card.");
        }
      } else if (formData.aadhar_card_url && !formData.aadhar_card_back_url) {
        // Case: Second file uploaded (back side of the Aadhar card)
        if (formData.aadhar_card_type === "image") {
          // If the first file is an image, allow the second image (back side)
          if (files[0].type.startsWith("image")) {
            const backFile = files[0];
            const backUrl = URL.createObjectURL(backFile);

            setFormData({
              ...formData,
              aadhar_card_back: backFile, // Store back image
              aadhar_card_back_url: backUrl, // Preview back image
              aadhar_card_type: "image", // Mark as image type
            });
          } else {
            // If not an image, show error
            alert("Please upload a valid image for the back side of the Aadhar card.");
          }
        } else if (formData.aadhar_card_type !== "image") {
          // If the first file is a document (PDF/Word), no second file can be uploaded
          alert("You can only upload one document for the Aadhar card.");
        }
      }
    } else {
      // If more than 2 files are uploaded, show error
      alert("You can only upload 2 files for the Aadhar card (front and back).");
    }

    // Reset the input field after handling the file
    const field = document.getElementById("aadhar_card") as HTMLInputElement | null;
    if (field) {
      field.value = "";
    }
  };


  const handleAadharRemoveImage = (side: "front" | "back") => {
    if (side === "front") {
      // Remove front image
      if (formData.aadhar_card_back_url) {
        // If back image exists, assign it to aadhar_card and aadhar_card_url
        setFormData({
          ...formData,
          aadhar_card: formData.aadhar_card_back, // Assign back image to front
          aadhar_card_url: formData.aadhar_card_back_url, // Preview back image
          aadhar_card_back: null, // Clear back image
          aadhar_card_back_url: "", // Clear back image URL
          delete_img: [
            ...formData.delete_img,
            formData.aadhar_card_back,
          ],
        });
      } else {
        // If no back image, clear the front image
        setFormData({
          ...formData,
          aadhar_card: null,
          aadhar_card_url: "",
          aadhar_card_type: "", // Reset type
          delete_img: [
            ...formData.delete_img,
            formData.aadhar_card,
          ],
        });
      }
    } else if (side === "back") {
      // Remove back image
      setFormData({
        ...formData,
        aadhar_card_back: null, // Clear the back image
        aadhar_card_back_url: "", // Clear back image URL
        delete_img: [
          ...formData.delete_img,
          formData.aadhar_card_back,
        ],
      });
    }

    const field = document.getElementById("aadhar_card") as HTMLInputElement | null;
    if (field) {
      field.value = ""; // Clear the file input field
    }
  };
  return (
    <Grid item xs={6}>
      <TextField
        fullWidth
        id="aadhar_card"
        name="aadhar_card"
        label="Aadhar Card"
        className="accessory_img"
        type="file"
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        inputProps={{ accept: "image/*,.doc,.docx,.pdf", multiple: true }}
        autoComplete="images"
        onChange={(e) => handleAadharDocumentChange(e, "aadhar_card")}
      />
      
      {/* Preview and remove for front image formData.aadhar_card_url  */}
      {formData.aadhar_card !== null && formData.aadhar_card_type === "image" && (
        <Grid item xs={12} className="imageDiv">
          <div>
            <div className="image-preview">
              <img
                src={formData.aadhar_card_url} // Only show the front image
                alt="Aadhar Card Front"
                className="preview-img"
              />
              <Button
                onClick={() => handleAadharRemoveImage("front")}
                style={{ textAlign: "center" }}
              >
                Remove Front
              </Button>
            </div>
          </div>
        </Grid>
      )}

      {/* Preview and remove for back image formData.aadhar_card_back_url */}
      {formData.aadhar_card_back !== null && formData.aadhar_card_type === "image" && (
        <Grid item xs={12} className="imageDiv">
          <div>
            <div className="image-preview">
              <img
                src={formData.aadhar_card_back_url} // Only show the back image
                alt="Aadhar Card Back"
                className="preview-img"
              />
              <Button
                onClick={() => handleAadharRemoveImage("back")}
                style={{ textAlign: "center" }}
              >
                Remove Back
              </Button>
            </div>
          </div>
        </Grid>
      )}

      {/* Preview and remove for PDF */}
      {formData.aadhar_card_type === "pdf" && formData.aadhar_card_url && (
        <Grid item xs={12} className="pdf-icon">
          <PictureAsPdfIcon /> Aadhar Card (PDF)
        </Grid>
      )}

      {/* Preview and remove for Word document */}
      {formData.aadhar_card_type === "word" && formData.aadhar_card_url && (
        <Grid item xs={12} className="pdf-icon">
          <DescriptionIcon /> Aadhar Card (Word Document)
        </Grid>
      )}

      {/* Remove button for document types formData.aadhar_card_url && */}
      {formData.aadhar_card_type !== "image" && formData.aadhar_card !== null && (
        <Button
          style={{ textAlign: "center" }}
          onClick={() => {
            setFormData({
              ...formData,
              aadhar_card: null, // Clear the front file
              aadhar_card_back: null, // Clear the back file
              aadhar_card_url: "", // Clear front URL
              aadhar_card_back_url: "", // Clear back URL
              aadhar_card_type: "", // Reset the file type
              delete_img: [
                ...formData.delete_img,
                formData.aadhar_card,
              ],
            });
            const thumbnailElement = document.getElementById("aadhar_card") as HTMLInputElement | null;
            if (thumbnailElement) {
              thumbnailElement.value = "";
            }
          }}
        >
          Remove Document
        </Button>
      )}

      <Error current_key="aadhar_card" />
    </Grid>
  );
};

export default AadharUploadComponent;
